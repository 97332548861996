import "photoswipe/dist/photoswipe.css";
import { Gallery, Item } from "react-photoswipe-gallery";

const galleryOptions = {
    arrowPrev: true,
    arrowNext: true,
    zoom: true,
    close: true,
    bgOpacity: 0.8
}

export default function PhotoGallery({ children, className }) {
    return (
        <>
            <Gallery withCaption options={galleryOptions}>
                <div className="grid grid-cols-1 gap-4 lg:grid-cols-2 xl:grid-cols-4">
                {
                    children.map((item, index) => {
                        if (item.props) {
                            return (<Item
                                key={index}
                                cropped
                                original={item.props.src}
                                alt={item.props.alt}
                                width="1920"
                                height="1080">
                                {({ ref, open }) => {
                                    return (
                                        <img ref={ref} onClick={open} src={item.props.src} />
                                    );
                                }}
                            </Item>)
                        }
                        return <></>
                    })
                }
                </div>
            </Gallery>
        </>
    );
}