import { Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import Portfolio from "./pages/Portfolio";
import Blog from "./pages/Blog";
import SingleBlog from "./pages/SingleBlog";
import SinglePortfolio from "./pages/SinglePortfolio";
import NavBar from "./components/NavBar";
import Footer from "./components/Footer";

export default function App() {
    // Future plan is to use React Transition Group when navigating.
    // It looks super nice!
    // https://reactcommunity.org/react-transition-group/with-react-router
    return (
        <main className="bg-white">
            <section className="min-h-screen">
            <NavBar />
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/portfolio">
                    <Route index element={<Portfolio />} />
                    <Route path="/portfolio/:slug" element={<SinglePortfolio />} />
                </Route>
                <Route path="/blog">
                    <Route index element={<Blog />} />
                    <Route path="/blog/:slug" element={<SingleBlog />} />
                </Route>
            </Routes>
            <Footer />
            </section>
        </main>
    );
}
