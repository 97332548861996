import { useEffect, useState } from "react";
import { useRef } from "react";
import PortfolioItemCard from "../components/PortfolioItemCard";

export default function Portfolio() {
    const [portfolioEntries, setPortfolioEntries] = useState([]);

    useEffect(() => {
        async function fetchData() {
            const scheme = window.location.protocol;
            const host = window.location.hostname;
            const port = 5000;
            const endpoint = scheme + "//" + host + ":" + port;
            // const backend_endpoint = process.env.REACT_APP_BACKEND_API_ENDPOINT;
            const response = await fetch(endpoint + "/api/portfolio");
            const json = await response.json();

            return json;
        }
        
        fetchData().then(value => setPortfolioEntries(value));
    }, [])

    return (
        <div className=" pt-16 mx-auto sm:max-w-xl md:max-w-full lg-py-20">
        {/*  */}
            <section className="">
                <div className="max-w-2xl mx-auto py-12">
                    <h1 className="text-2xl font-medium">Portfolio</h1>
                    <p className="text-lg">
                        These are projects I've worked on over the years. Some of them are professional projects I did for work. Others were just for fun.
                    </p>
                </div>
            </section>
            <div className="bg-neutral-100">
                <div className="px-4 py-10 md:px-24 grid gap-8 md:grid-cols-2 lg:grid-cols-3 sm:mx-auto">
                    {
                        portfolioEntries.map((item, index) => (<PortfolioItemCard key={item.frontMatter.id} model={item}/>))
                    }
                </div>
            </div>
        </div>
    );
}
