import { IconContext } from 'react-icons';
import { FaLinkedin } from 'react-icons/fa'
import { FaGithubSquare } from 'react-icons/fa';

export default function Footer() {
    return (
        <footer className="py-3 my-4 border-t-2">
            <div className="flex px-4 justify-between items-center">
                <span>&copy; 2024 - Ernesto Rojo </span>
                <IconContext.Provider value={{ size: "24px" }}>
                    <ul className="flex justify-end">
                        <li className="ml-3">
                            <a href="https://www.linkedin.com/in/ernestorojojr">
                                <FaLinkedin />
                            </a>
                        </li>
                        <li className="ml-3">
                            <a href="http://www.github.com/vogles">
                                <FaGithubSquare />
                            </a>
                        </li>
                    </ul>
                </IconContext.Provider>
            </div>
        </footer>
    );
}