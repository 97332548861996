import { format } from 'date-fns';

const dateOptions = {
    year: "numeric",
    month: "long",
    day: "numeric"
};

export default function PortfolioItemCard({model}) {
    let blogEntryUrl = `/${model.frontMatter.category}/${model.frontMatter.slug}`;
    return (
        <div className="transition-shadow duration-300 bg-white rounded shadow-sm">
            <img
                src={model.frontMatter.thumbnailImage}
                className="object-cover w-full h-64"
                alt="" />
            <div className="p-5 border border-t-0">
                <p className="mb-3 text-xs font-semibold tracking-wide uppercase">
                    {/* <a href=""
                        aria-label="Category"
                        title="traveling"
                        className="transition-colors duration-200 text-blue-gray-900 hover:text-deep-purple-accent-700">
                        traveling
                    </a> */}
                    {/* <span className="text-gray-600">-  Dec 28, 2020</span> */}
                    <span className="text-gray-600">{format(new Date(model.frontMatter.publishDate), "MMMM d yyyy")}</span>
                </p>
                <a href={blogEntryUrl}
                    aria-label="Category"
                    title={model.frontMatter.title}
                    className="inline-block mb-3 text-2xl font-bold leading-5 hover:text-red-200">
                    {model.frontMatter.title}
                </a>
                <p className="mb-2 text-gray-700 line-clamp-3">
                    {model.frontMatter.excerpt}
                </p>
                <a href={blogEntryUrl}
                    aria-label=""
                    className="my-2 p-2 items-center font-semibold bg-red-500 text-white rounded-md hover:bg-red-600">
                    Read More
                </a>
            </div>
        </div>
    );
}