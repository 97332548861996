import { SlMenu } from 'react-icons/sl';
import { useState } from 'react';

export default function NavBar() {
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    return (
        <div className="bg-gray-900">
            <div className="px-4 py-5 mx-auto sm:max-w-xl md:max-w-full">
                <div className="relative flex items-center justify-between">
                    <a href="/">
                        <span className="ml-2 text-xl font-bold tracking-wide text-gray-100 uppercase">Ernesto Rojo</span>
                    </a>
                    <ul className="items-center space-x-8 hidden lg:flex">
                        <li>
                            <a
                                href="/"
                                className="font-medium tracking-wide text-gray-100 transition-colors duration-200 hover:text-red-400"
                                aria-label="Home"
                                title="Home">
                                Home
                            </a>
                        </li>
                        <li>
                            <a
                                href="/portfolio"
                                className="font-medium tracking-wide text-gray-100 transition-colors duration-200 hover:text-red-400"
                                aria-label="Portfolio"
                                title="Portfolio">
                                Portfolio
                            </a>
                        </li>
                        <li>
                            <a
                                href="/blog"
                                className="font-medium tracking-wide text-gray-100 transition-colors duration-200 hover:text-red-400"
                                aria-label="Blog"
                                title="Blog">
                                Blog
                            </a>
                        </li>
                    </ul>
                    <div className="lg:hidden">
                        <button
                            aria-label="Toggle Menu"
                            title="Toggle Menu"
                            className=""
                            onClick={() => setIsMenuOpen(!isMenuOpen)}>
                            <SlMenu className="text-white" />
                        </button>
                    </div>
                </div>
                {isMenuOpen && (
                    <div className="w-full">
                        <div className="p-5 bg-white border rounded shadow-sm">
                            <nav>
                                <ul className="space-y-4">
                                    <li>
                                        <a
                                            href="/"
                                            className="font-medium tracking-wide text-gray-700 transition-colors duration-200 hover:text-red-400"
                                            aria-label="Home"
                                            title="Home">
                                            Home
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            href="/portfolio"
                                            className="font-medium tracking-wide text-gray-700 transition-colors duration-200 hover:text-red-400"
                                            aria-label="Portfolio"
                                            title="Portfolio">
                                            Portfolio
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            href="/blog"
                                            className="font-medium tracking-wide text-gray-700 transition-colors duration-200 hover:text-red-400"
                                            aria-label="Blog"
                                            title="Blog">
                                            Blog
                                        </a>
                                    </li>
                                </ul>
                                
                            </nav>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}
