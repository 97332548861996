import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Markdown from 'markdown-to-jsx';
import PhotoGallery from "../components/PhotoGallery";
import MarkdownMediaLink from "../components/MarkdownMediaLink";
// import "../assets/css/github-markdown-css/github-markdown-dark.min.css"

export default function SingleBlog() {
    const { slug } = useParams();
    const [ content, setContent] = useState(null);

    useEffect(() => {
            async function fetchData(id) {
                const scheme = window.location.protocol;
                const host = window.location.hostname;
                const port = 5000;
                const endpoint = scheme + "//" + host + ":" + port;
                // const backend_endpoint = process.env.REACT_APP_BACKEND_API_ENDPOINT;
                const response = await fetch(endpoint + "/api/blog/" + id)
                const json = await response.json();
    
                return json;
            }
            
            fetchData(slug).then(value => setContent(value));
        }, [])

    return (
        <>
        {content != null && (
            <>
            <div className="container mx-auto py-5">
                <img className="mx-auto" src={content.frontMatter.featuredImage} />
            </div>
            <div className="bg-neutral-100 py-5">
                <div className="container mx-auto">
                    <h1 className="text-center my-3 text-4xl">{content.frontMatter.title}</h1>
                    <Markdown className="markdown-body" options={{
                        overrides: {
                            PhotoGallery: {
                                component: PhotoGallery
                            },
                            img: {
                                component: MarkdownMediaLink
                            },
                            h1: {
                                props: {
                                    className: "text-4xl"
                                }
                            },
                            h2: {
                                props: {
                                    className: "mb-2 text-3xl font-medium"
                                }
                            },
                            h3: {
                                props: {
                                    className: "text-2xl"
                                }
                            },
                            h4: {
                                props: {
                                    className: "mb-2 text-xl font-medium"
                                }
                            },
                            h5: {
                                props: {
                                    className: "text-lg"
                                }
                            },
                            h6: {
                                props: {
                                    className: "text-md"
                                }
                            },
                            hr: {
                                props: {
                                    className: "my-4 border-solid border-t-[1px]"
                                }
                            },
                            p: {
                                props: {
                                    className: "mb-4"
                                }
                            }
                        }
                    }}>{ content.raw }</Markdown>
                </div>
            </div>
            </>
        )}
        </>
    );
}